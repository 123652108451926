console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);

// Top Scroll button element
//$('body').materialScrollTop();

// anchor link
// $(document).on('click', 'a[href^="#"]', function (event) {
//   event.preventDefault();

//   $('html, body').animate({
//     scrollTop: $($.attr(this, 'href')).offset().top
//   }, 500);
// });




